import React, { useState, useEffect } from "react";
import "../Matches/./Match.css";
import Navbar from "../Header/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import AllSide from "./AllSide";
import "./AllSide.css";
import Table_Buttons from "../Table_Buttons/Table_Button";
import moment from "moment";
import { API } from "../../API";

function Unsellected() {
  const user = sessionStorage.getItem("user");
  let ress = JSON.parse(user);
  let UserId = ress.resultid;
  const [tableData, setTableData] = useState([]);
  const [todate, settodate] = useState('')
  const [fromdate, setfromdate] = useState('')
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(100);

  const UnsettledBet_Api = async () => {
    try {
      let res = await API.post("getUnSetteledBet", {
        "uid": UserId,
        "fromDate": fromdate,
        "toDate": todate

      }
      );
      res = res.data.data;
      console.log("UnsettledBet_Api", res);
      setTableData(res);
    } catch (e) {
      console.log("Error While Fatch Bet_History API", e);
    }
  };

  useEffect(() => {
    UnsettledBet_Api();
  }, []);



  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = tableData.slice(indexOfFirstPage, indexOfLastPost);

  return (
    <div>
      <Navbar data_Dashboard={"All"} />
      <main class="main_root wrapper">
      <div style={{ backgroundColor: "#9595a4" }} className="sidebar_respon">
        <AllSide />
        </div>
        {/* <!-----=======body section start=======----> */}
        <div id="content">
          <div class="container">
            <div class="row">
              <div class="col-md-12 ">
                <div class="section_bg">
                  <div class="mybet_wedget">
                    <div class="bars_bg">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="section_heading">
                            <h2>Un-Setteled Bet</h2>
                            {/* <a className="btn" href="/Bet_History" >Bet History</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <from>
                        <div className="row mod_12" >
                          <div className="col-md-2 col-6">
                            <label className="fromdate">From Date</label>
                            <input className="from-control" type="date" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                          </div>
                          <div className="col-md-2 col-6">
                            <label className="todate">To Date</label>
                            <br />
                            <input className="from-control" type="date" value={todate} onChange={(e) => settodate(e.target.value)} />
                          </div>
                          <div className="col-md-3 col-12">
                            <label className="todate"></label>
                            <br />
                            <input
                              className="btn submit_BTN"
                              // type="submit"
                              value="Submit"
                              onClick={() => UnsettledBet_Api()}
                              readOnly
                            />
                          </div>
                        </div>
                        <br />
                      </from>
                    </div>
                    <br />
                    <div class="mybet_table">
                      <div class="table-responsive">
                        <table class="table">
                          <thead className="Statement_head">
                            <tr>
                              <th>No</th>
                              <th>Place Date</th>
                              <th>Event Name</th>
                              <th>Match Name</th>
                              <th>Nation</th>
                              <th>Yes</th>
                              <th>No</th>
                              <th>Event Type</th>
                             <th>%</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentPost?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{(currentPage - 1) * listPerpage + index + 1}</td>
                                <td>{moment(item.edate).format("DD/MM/YYYY h:m:s A")}</td>
                                <td>{item.remark}</td>
                                <td>{item.name}</td>
                                <td>{item.team}</td>
                                <td>{item.back}</td>
                                <td>{item.lay}</td>
                                <td>{item.type}</td>
                                <td>{(item.type=="Match Odds"||item.type=="Bookmaker Market")?'-':item?.BackSize1>0?item?.BackSize1:item?.LaySize1}</td>
                                <td>{item.stake}</td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <Table_Buttons
                          indexOfFirstPage={indexOfFirstPage}
                          indexOfLastPost={indexOfLastPost}
                          setcurrentPage={setcurrentPage}
                          currentPage={currentPage}
                          totalData={tableData.length}
                          listPerpage={listPerpage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-----=======body section end=========----> */}
      </main>
    </div>
  );
}

export default Unsellected;
