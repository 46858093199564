import React from "react";
import "./Login.css";
function Forgot() {
  return (
    <div className="main_loginPage">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="text-center mt-4">
              <img
                className="w-50"
                src="/assets/images/bet21-logo.png"
                alt=""
              />
            </div>
            <div className="login_form new_login_form registerForm mt-4">
              <h1 className="for_h1">Recover Password</h1>
              <form>
                <div className="form-group">
                  <div className="inpuT_main_div">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Login Id"
                      name="loginId"
                    />
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      className="input_icons"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
                    </svg>
                  </div>
                </div>

                <div className="login_btn">
                  <button type="button" className="btn button btn-block">
                    Recover password{" "}
                  </button>
                </div>
                <p>
                  Don’t have an account yet? <a href="/Registration">Sign Up</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
