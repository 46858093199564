import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import "./Dashboard.css";
import tv from "./tv-solid.svg";
import { API, API_Match } from "../../API";
import SideBar2 from "../Dropdown/SideBar2";
import football from "./1.png";
import tannis from "./2.png";
import criball from "./4.png";
import horse from "./5.png";
import Mobile_side_bar from "../Mobile_side_bar/Mobile_side_bar";
import img1 from "./img/1.png";
import img4 from "./img/4.png";
import img7 from "./img/7.png";
import img4339 from "./img/4339.png";
import img99990 from "./img/99990.png";
import img99994 from "./img/99994.png";
import img237 from "./img/2378961.png";
import Loading from "../Loading/Loading";



function Dashboard() {
  const token = sessionStorage.getItem("token");
  let { name, type } = useParams();
  const [events_Data, setevents_Data] = useState([]);
  const [eventCatagory_Data, seteventCatagory_Data] = useState([]);
  const [eventCatagorydata, seteventCatagory] = useState([]);
  const [event_Type, setevent_Type] = useState(0);
  const [event_name, setevent_name] = useState("");
  const [select, setSelect] = useState("Time");
  const [Spinner, setSpinner] = useState(false);

  console.log("");

  setTimeout(() => {
    sessionStorage.removeItem(token);
  }, 5 * 60 * 1000);

  let navigate = useNavigate();

  const Current_Match = async (EventTypeID) => {
    try {
      setSpinner(true);
      let res = await API.get(
        `GetAllCurrentMatches?eventTypeID=${EventTypeID}&fromDate=" "&toDate=" "&searchByName=" "`
      );
      // res = res.data.data;
      seteventCatagory_Data(res.data.data);
      setSpinner(false);

      console.log("Current_Match", res.data.data);
    } catch (e) {
      setSpinner(false);

      console.log("Error While Fatch Current_Match API", e);
    }
  };

  const groupedData = eventCatagory_Data.reduce((groups, item) => {
    const key = item.ComName;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
    return groups;
  }, {});

  useEffect(() => {
    getAllEvents();
  }, []);




  const getAllEvents = async () => {
    try {
      setSpinner(true);

      let res = await API.get("GetAllMatches");
      console.log(" getAllEvents =>", res.data.data);
      setevents_Data(res.data.data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);

      console.log("Something Error in getAllEvents API", error);
    }
  };

  // const eventCatagory = async (EventTypeID) => {
  //   try {
  //     setSpinner(true);

  //     // console.log("EventType=>", EventTypeID, event_name);
  //     let res = await API_Match.get(
  //       `GetAllCompetitionsData?EventTypeID=${EventTypeID}`
  //     );
  //     console.log("Res", res.data);
  //     seteventCatagory(res.data);
  //     setSpinner(false);
  //   } catch (error) {
  //     setSpinner(false);

  //     console.log("Something Error in eventCatagory API", error);
  //   }
  // };

  // console.log("name",name);

  function handleClick() {
    let eventType1 = type ?? "0";
    let name1 = name ?? "InPlay";
    // eventCatagory(eventType1);
    Current_Match(eventType1);
    setevent_Type(eventType1);
    setevent_name(name1);
  }

  useEffect(() => {
    handleClick();
  }, [name, type]);

  return (
    <div>
      <Navbar data_Dashboard={"none"} />
      <main className="main_root wrapper">
        <div style={{ backgroundColor: "#9595a4" }} className="sidebar_respon">
          <SideBar2 />
        </div>
        {/*<!-----=======body section start=======----> */}
        <div className="dashbd" id="">
          <div className="">
            {Spinner ? (
              <>
                <Loading />

              </>
            ) : (
              <>
                <ul className="blink2">
                  {eventCatagory_Data?.map((item, index) => {
                    const currentTime = new Date();
                    const itemTime = new Date(item.openDate);
                    if (itemTime < currentTime) {
                      return (
                        <li key={index}>
                          <a
                            className="Match_Name"
                            href=""
                            style={{ textDecoration: "none" }}
                            onClick={() =>
                              navigate(
                                event_name === "Tennis"
                                  ? `/Tennis_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                  : event_name === "Soccer"
                                    ? `/Football_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                    : `/Live_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                              )
                            }
                          >
                            <span style={{ color: "#fff" }}>
                              {" "}
                              {item.eventTypeid == "1" ? (
                                <td>
                                  <img
                                    src={football}
                                    width="15px"
                                    style={{ marginRight: "5px" }}
                                  />
                                </td>
                              ) : item.eventTypeid == "2" ? (
                                <td>
                                  <img
                                    src={tannis}
                                    width="15px"
                                    style={{ marginRight: "5px" }}
                                  />
                                </td>
                              ) : item.eventTypeid == "4" ? (
                                <td>
                                  <img
                                    src={criball}
                                    width="15px"
                                    style={{ marginRight: "5px" }}
                                  />
                                </td>
                              ) : (
                                <td>
                                  <img
                                    src={horse}
                                    width="15px"
                                    style={{ marginRight: "5px" }}
                                  />
                                </td>
                              )}
                              {"  "}
                              {item.name}
                            </span>
                          </a>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
                </ul>

                <div className=" tab-content_bg">
                  {/* <Mobile_side_bar /> */}

                  <div className="">
                    <ul className="nav nav-pills" role="tablist">
                      {events_Data.slice(0, 12).map((items, index) => {
                        return (
                          <>
                            <li className="nav-item nav_pils" key={index}>
                              <a
                                href=""
                                className="nav-link  inplay_dis"
                                data-toggle="pill"
                                onClick={() => (
                                  // eventCatagory(items.eventType),
                                  Current_Match(items.eventType),
                                  setevent_Type(items.eventType),
                                  setevent_name(items.name),
                                  navigate(
                                    `/Dashboard/${items.name}/${items.eventType}`
                                  )
                                )}
                              >
                                {/* <p ></p> */}
                                <span className="booo">{items.name==='Soccer'?'Football':items.name}</span>
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                  <ul
                    role="tablist"
                    class="nav nav-tabs game-nav-bar custom_tb_nav"
                    aria-label="Tabs"
                  >
                    <li class=" nav-item">
                      <a
                        href="/Dashboard/Cricket/4"
                        role="tab"
                        class={
                          name == "Cricket"
                            ? "nav-link  active"
                            : "nav-link  nav_link_color"
                        }
                        aria-controls=""
                        aria-selected="true"
                        id=""
                      >
                        <span></span>
                        <div>
                          <img alt="" src={img4} />
                        </div>
                        <div>Cricket</div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="/Dashboard/Soccer/1"
                        role="tab"
                        class={
                          name == "Soccer"
                            ? "nav-link  active"
                            : "nav-link  nav_link_color"
                        }
                        aria-controls=""
                        aria-selected="false"
                        id=""
                      >
                        <span></span>
                        <div>
                          <img alt="" src={img1} />
                        </div>
                        <div>Football</div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="/Dashboard/Tennis/2"
                        role="tab"
                        class={
                          name == "Tennis"
                            ? "nav-link  active"
                            : "nav-link  nav_link_color"
                        }
                        aria-controls=""
                        aria-selected="false"
                        id=""
                      >
                        <span></span>
                        <div>
                          <img alt="" src={img7} />
                        </div>
                        <div>Tennis</div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="/Dashboard/Horse%20Racing/7"
                        role="tab"
                        class={
                          name == "Horse Racing"
                            ? "nav-link  active"
                            : "nav-link  nav_link_color"
                        }
                        aria-controls=""
                        aria-selected="false"
                        id=""
                      >
                        <span></span>
                        <div>
                          <img alt="" src={img7} />
                        </div>
                        <div>Horse Racing</div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="/Dashboard/Greyhound%20Racing/4339"
                        role="tab"
                        class={
                          name == "Greyhound Racing"
                            ? "nav-link  active"
                            : "nav-link  nav_link_color"
                        }
                        aria-controls=""
                        aria-selected="false"
                        id=""
                      >
                        <span></span>
                        <div>
                          <img alt="" src={img4339} />
                        </div>
                        <div>Greyhound Racing</div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="/Dashboard/Kabaddi/11"
                        role="tab"
                        class={
                          name == "Kabaddi"
                            ? "nav-link  active"
                            : "nav-link  nav_link_color"
                        }
                        aria-controls=""
                        aria-selected="false"
                        id=""
                      >
                        <span></span>
                        <div>
                          <img alt="" src={img99994} />
                        </div>
                        <div>Kabaddi</div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="/Dashboard/Politics/12"
                        role="tab"
                        class={
                          name == "Politics"
                            ? "nav-link  active"
                            : "nav-link  nav_link_color"
                        }
                        aria-controls=""
                        aria-selected="false"
                        id=""
                      >
                        <span></span>
                        <div>
                          <img alt="" src={img237} />
                        </div>
                        <div>Politics</div>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        role="tab"
                        class={
                          name == "Politicsa"
                            ? "nav-link  active"
                            : "nav-link  nav_link_color"
                        }
                        aria-controls=""
                        aria-selected="false"
                        id=""
                      >
                        <span></span>
                        <div>
                          <img alt="" src={img99990} />
                        </div>
                        <div class="new-tag-menus">Binary</div>
                      </a>
                    </li>
                  </ul>
                  <div className="container">
                    <div className="filter-ct right right_mob ">
                      <label>View by:</label>
                      <select class="form-control-sm" value={select} onChange={(e) => setSelect(e.target.value)}>
                        <option value="Time">Time</option>
                        <option value="Competitions">Competitions</option>
                      </select>
                    </div>
                    {/* <!-----tab pane------>  */}

                    <div className="tab-content">
                      <div className="tab-content">
                        <div id="Home3" className="container tab-pane active" >
                          <div className="">
                            <div className="table-responsive-sm tabl">
                              <table className="table">
                                <thead>
                                  <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col"></th>
                                    <th scope="col">Game</th>
                                    <th scope="col">OpenDate</th>
                                    <th scope="col"></th>
                                    <th scope="col" className="temp2"></th>
                                    <th scope="col" className="temp">
                                      1
                                    </th>
                                    <th scope="col" className="temp">
                                      X
                                    </th>
                                    <th scope="col" className="temp">
                                      2
                                    </th>
                                  </tr>
                                </thead>
                                {select == "Time" ?
                                  <tbody className="text-white">
                                    {eventCatagory_Data?.map((item, index) => (
                                      <tr>
                                        {/* <th scope="row">{index + 1}</th> */}
                                        {item.eventTypeid == "1" ? (
                                          <td>
                                            <img
                                              className="cls_int"
                                              src={football}
                                              width="15px"
                                              style={{ marginRight: "5px" }}
                                            />
                                          </td>
                                        ) : item.eventTypeid == "2" ? (
                                          <td>
                                            <img
                                              className="cls_int"
                                              src={tannis}
                                              width="15px"
                                              style={{ marginRight: "5px" }}
                                            />
                                          </td>
                                        ) : item.eventTypeid == "4" ? (
                                          <td>
                                            <img
                                              className="cls_int"
                                              src={criball}
                                              width="15px"
                                              style={{ marginRight: "5px" }}
                                            />
                                          </td>
                                        ) : (
                                          <td>
                                            <img
                                              className="cls_int"
                                              src={horse}
                                              width="15px"
                                              style={{ marginRight: "5px" }}
                                            />
                                          </td>
                                        )}

                                        <td>
                                          <a
                                            href=""
                                            style={{
                                              color: "grey",
                                              textDecoration: "none",
                                              textAlign: "center",
                                            }}
                                            onClick={() =>
                                              navigate(
                                                event_name === "Tennis"
                                                  ? `/Tennis_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                                  : event_name === "Soccer"
                                                    ? `/Football_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                                    : `/Live_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                              )
                                            }
                                          >
                                            {item.name}-
                                            {/* {moment(item.openDate).format(
                                            "DD/MM/YYYY h:m:s A"
                                          )} */}
                                          </a>
                                        </td>
                                        <td>
                                          {moment(item.openDate)
                                            .tz("Asia/Kolkata")
                                            .format("DD/MM/YYYY h:m:s A")}
                                        </td>
                                        {new Date(item.openDate) <= new Date() ? (
                                          <td scope="row">
                                            {new Date(item.openDate) <=
                                              new Date() && (
                                                <span className="circle"> </span>
                                              )}
                                          </td>
                                        ) : (
                                          <td scope="row">
                                            <span className="">- </span>
                                          </td>
                                        )}
                                        {new Date(item.openDate) <= new Date() ? (
                                          <td>
                                            {new Date(item.openDate) <=
                                              new Date() && (
                                                <img src={tv} alt="" width="14" />
                                              )}
                                          </td>
                                        ) : (
                                          <td>
                                            <span>-</span>
                                          </td>
                                        )}
                                        {/* <OddDetails key={item.id} id={item.id} /> */}
                                        <td className="temp2">
                                          <span className="back_space5">{item.Back_1}</span>
                                          <span className="back_space15">{item.Lay_1}</span>
                                        </td>
                                        <td className="temp2">
                                          <span className="back_space5">{item.Back_x}</span>
                                          <span className="back_space15">{item.Lay_x}</span>
                                        </td>
                                        <td className="temp2">
                                          <span className="back_space5">{item.Back_2}</span>
                                          <span className="back_space15">{item.Lay_2}</span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  :
                                  <tbody className="text-white">
                                    {Object.keys(groupedData).map(ComName => (
                                      <React.Fragment key={ComName}>
                                        <tr className="ComName">
                                          <th className="ComName" colSpan="3">{ComName}</th>
                                        </tr>
                                        {groupedData[ComName].map(item => (
                                          <tr>
                                            {/* <th scope="row">{index + 1}</th> */}
                                            {item.eventTypeid == "1" ? (
                                              <td>
                                                <img
                                                  className="cls_int"
                                                  src={football}
                                                  width="15px"
                                                  style={{ marginRight: "5px" }}
                                                />
                                              </td>
                                            ) : item.eventTypeid == "2" ? (
                                              <td>
                                                <img
                                                  className="cls_int"
                                                  src={tannis}
                                                  width="15px"
                                                  style={{ marginRight: "5px" }}
                                                />
                                              </td>
                                            ) : item.eventTypeid == "4" ? (
                                              <td>
                                                <img
                                                  className="cls_int"
                                                  src={criball}
                                                  width="15px"
                                                  style={{ marginRight: "5px" }}
                                                />
                                              </td>
                                            ) : (
                                              <td>
                                                <img
                                                  className="cls_int"
                                                  src={horse}
                                                  width="15px"
                                                  style={{ marginRight: "5px" }}
                                                />
                                              </td>
                                            )}

                                            <td>
                                              <a
                                                href=""
                                                style={{
                                                  color: "grey",
                                                  textDecoration: "none",
                                                  textAlign: "center",
                                                }}
                                                onClick={() =>
                                                  navigate(
                                                    event_name === "Tennis"
                                                      ? `/Tennis_Matches?Id=${item.id}&Time=${item.openDate}`
                                                      : event_name === "Soccer"
                                                        ? `/Football_Matches?Id=${item.id}&Time=${item.openDate}`
                                                        : `/Live_Matches?Id=${item.id}&Time=${item.openDate}`
                                                  )
                                                }
                                              >
                                                {item.name}-
                                                {/* {moment(item.openDate).format(
                                              "DD/MM/YYYY h:m:s A"
                                            )} */}
                                              </a>
                                            </td>
                                            <td>
                                              {moment(item.openDate)
                                                .tz("Asia/Kolkata")
                                                .format("DD/MM/YYYY h:m:s A")}
                                            </td>
                                            {new Date(item.openDate) <= new Date() ? (
                                              <td scope="row">
                                                {new Date(item.openDate) <=
                                                  new Date() && (
                                                    <span className="circle"> </span>
                                                  )}
                                              </td>
                                            ) : (
                                              <td scope="row">
                                                <span className="">- </span>
                                              </td>
                                            )}
                                            {new Date(item.openDate) <= new Date() ? (
                                              <td>
                                                {new Date(item.openDate) <=
                                                  new Date() && (
                                                    <img src={tv} alt="" width="14" />
                                                  )}
                                              </td>
                                            ) : (
                                              <td>
                                                <span>-</span>
                                              </td>
                                            )}
                                            {/* <OddDetails key={item.id} id={item.id} /> */}
                                            <td className="temp2">
                                              <span className="back_space5">{item.Back_1}</span>
                                              <span className="back_space15">{item.Lay_1}</span>
                                            </td>
                                            <td className="temp2">
                                              <span className="back_space5">{item.Back_x}</span>
                                              <span className="back_space15">{item.Lay_x}</span>
                                            </td>
                                            <td className="temp2">
                                              <span className="back_space5">{item.Back_2}</span>
                                              <span className="back_space15">{item.Lay_2}</span>
                                            </td>
                                          </tr>
                                        ))}
                                      </React.Fragment>
                                    ))}
                                  </tbody>
                                }
                              </table>
                            </div>
                            
                            {/* mobile mode table start  */}
                            <div className="mainMobile">
                            {select == "Time" 
                            ?
                            (
                             

                            eventCatagory_Data?.map((item, index) => (
                            <div className="none">
                              
                              <div className="mainT">
                                <div className="">
                                    <h5 className="Hname"><a href="" class="main_color"
                                    onClick={() =>
                                      navigate(
                                        event_name === "Tennis"
                                          ? `/Tennis_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                          : event_name === "Soccer"
                                            ? `/Football_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                            : `/Live_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                      )
                                    }
                                    
                                    >  {item.name}</a></h5>
                                    <p className="Hpara"> {moment(item.openDate)
                                            .tz("Asia/Kolkata")
                                            .format("DD/MM/YYYY h:m:s A")}</p>
                                </div>
                                <div className="">
                                   <span class="circle"> </span>&nbsp;&nbsp;
                                   <img src="/static/media/tv-solid.0778e05556c1aa9e12f519d51d857ef1.svg" alt="" width="14"></img>
                                </div>
                              </div>
                              <div className="maintt">
                                    <div scope="col" className="temp">
                                      1
                                    </div>
                                    <div scope="col" className="temp">
                                      X
                                    </div>
                                    <div scope="col" className="temp">
                                      2
                                    </div>
                              </div>
                              <div className="maintt1">
                                     <div className="game-col">
								                     	<span class="main_blue">{item.Back_1}</span>
								                     	<span class="main_pink">{item.Lay_1}</span>
                                       
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">{item.Back_x}</span>
								                     	<span class="main_pink">{item.Lay_x}</span>
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">{item.Back_2}</span>
								                     	<span class="main_pink">{item.Lay_2}</span>
								                     </div>
                              </div>
                            </div>
                            )))
                            
                             :(
                              <tbody className="text-white">
                                    {Object.keys(groupedData).map(ComName => (
                                      <React.Fragment key={ComName}>
                                        <tr className="ComName">
                                          <th className="ComName" colSpan="3">{ComName}</th>
                                        </tr>
                                        {groupedData[ComName].map(item => (
                                          <div className="none">
                                          <div className="mainT">
                                            <div className="">
                                                <h5 className="Hname"><a href="" class="main_color"
                                                onClick={() =>
                                                  navigate(
                                                    event_name === "Tennis"
                                                      ? `/Tennis_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                                      : event_name === "Soccer"
                                                        ? `/Football_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                                        : `/Live_Matches?Id=${item.id}&Time=${item.openDate}&m_Id=${item.MarketID}&e_Id=${item.eventTypeid}`
                                                  )
                                                }
                                                
                                                >  {item.name}</a></h5>
                                                <p className="Hpara"> {moment(item.openDate)
                                                        .tz("Asia/Kolkata")
                                                        .format("DD/MM/YYYY h:m:s A")}</p>
                                            </div>
                                            <div className="">
                                               <span class="circle"> </span>&nbsp;&nbsp;
                                               <img src="/static/media/tv-solid.0778e05556c1aa9e12f519d51d857ef1.svg" alt="" width="14"></img>
                                            </div>
                                          </div>
                                          <div className="maintt">
                                                <div scope="col" className="temp">
                                                  1
                                                </div>
                                                <div scope="col" className="temp">
                                                  X
                                                </div>
                                                <div scope="col" className="temp">
                                                  2
                                                </div>
                                          </div>
                                          <div className="maintt1">
                                                 <div className="game-col">
                                                   <span class="main_blue">{item.Back_1}</span>
                                                   <span class="main_pink">{item.Lay_1}</span>
                                                   
                                                 </div>
                                                 <div className="game-col">
                                                   <span class="main_blue">{item.Back_x}</span>
                                                   <span class="main_pink">{item.Lay_x}</span>
                                                 </div>
                                                 <div className="game-col">
                                                   <span class="main_blue">{item.Back_2}</span>
                                                   <span class="main_pink">{item.Lay_2}</span>
                                                 </div>
                                          </div>
                                        </div>
                                        ))}
                                      </React.Fragment>
                                    ))}
                                  </tbody>
                             )
                             
                             
                             
                             }
                            {/* <div className="none">
                              <div className="mainT">
                                <div className="">
                                    <h5 className="Hname"><a href="" class="main_color">Moro Canas v Barrere</a></h5>
                                    <p className="Hpara">Sep 19 2023 15:00 (IST)</p>
                                </div>
                                <div className="">
                                   <span class="circle"> </span>&nbsp;&nbsp;
                                   <img src="/static/media/tv-solid.0778e05556c1aa9e12f519d51d857ef1.svg" alt="" width="14"></img>
                                </div>
                              </div>
                              <div className="maintt">
                                    <div scope="col" className="temp">
                                      1
                                    </div>
                                    <div scope="col" className="temp">
                                      X
                                    </div>
                                    <div scope="col" className="temp">
                                      2
                                    </div>
                              </div>
                              <div className="maintt1">
                                     <div className="game-col">
								                     	<span class="main_blue">1.1</span>
								                     	<span class="main_pink">20</span>
                                       
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">10</span>
								                     	<span class="main_pink">0</span>
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">50</span>
								                     	<span class="main_pink">60</span>
								                     </div>
                              </div>

                            </div>

                            <div className="none">
                              <div className="mainT">
                                <div className="">
                                    <h5 className="Hname"><a href="" class="main_color">Moro Canas v Barrere</a></h5>
                                    <p className="Hpara">Sep 19 2023 15:00 (IST)</p>
                                </div>
                                <div className="">
                                   <span class="circle"> </span>&nbsp;&nbsp;
                                   <img src="/static/media/tv-solid.0778e05556c1aa9e12f519d51d857ef1.svg" alt="" width="14"></img>
                                </div>
                              </div>
                              <div className="maintt">
                                    <div scope="col" className="temp">
                                      1
                                    </div>
                                    <div scope="col" className="temp">
                                      X
                                    </div>
                                    <div scope="col" className="temp">
                                      2
                                    </div>
                              </div>
                              <div className="maintt1">
                                     <div className="game-col">
								                     	<span class="main_blue">1.1</span>
								                     	<span class="main_pink">20</span>
                                       
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">10</span>
								                     	<span class="main_pink">0</span>
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">50</span>
								                     	<span class="main_pink">60</span>
								                     </div>
                              </div>

                            </div>

                            <div className="none">
                              <div className="mainT">
                                <div className="">
                                    <h5 className="Hname"><a href="" class="main_color">Moro Canas v Barrere</a></h5>
                                    <p className="Hpara">Sep 19 2023 15:00 (IST)</p>
                                </div>
                                <div className="">
                                   <span class="circle"> </span>&nbsp;&nbsp;
                                   <img src="/static/media/tv-solid.0778e05556c1aa9e12f519d51d857ef1.svg" alt="" width="14"></img>
                                </div>
                              </div>
                              <div className="maintt">
                                    <div scope="col" className="temp">
                                      1
                                    </div>
                                    <div scope="col" className="temp">
                                      X
                                    </div>
                                    <div scope="col" className="temp">
                                      2
                                    </div>
                              </div>
                              <div className="maintt1">
                                     <div className="game-col">
								                     	<span class="main_blue">1.1</span>
								                     	<span class="main_pink">20</span>
                                       
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">10</span>
								                     	<span class="main_pink">0</span>
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">50</span>
								                     	<span class="main_pink">60</span>
								                     </div>
                              </div>

                            </div>

                            <div className="none">
                              <div className="mainT">
                                <div className="">
                                    <h5 className="Hname"><a href="" class="main_color">Moro Canas v Barrere</a></h5>
                                    <p className="Hpara">Sep 19 2023 15:00 (IST)</p>
                                </div>
                                <div className="">
                                   <span class="circle"> </span>&nbsp;&nbsp;
                                   <img src="/static/media/tv-solid.0778e05556c1aa9e12f519d51d857ef1.svg" alt="" width="14"></img>
                                </div>
                              </div>
                              <div className="maintt">
                                    <div scope="col" className="temp">
                                      1
                                    </div>
                                    <div scope="col" className="temp">
                                      X
                                    </div>
                                    <div scope="col" className="temp">
                                      2
                                    </div>
                              </div>
                              <div className="maintt1">
                                     <div className="game-col">
								                     	<span class="main_blue">1.1</span>
								                     	<span class="main_pink">20</span>
                                       
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">10</span>
								                     	<span class="main_pink">0</span>
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">501</span>
								                     	<span class="main_pink">60</span>
								                     </div>
                              </div>

                            </div>

                            <div className="none">
                              <div className="mainT">
                                <div className="">
                                    <h5 className="Hname"><a href="" class="main_color">Moro Canas v Barrere</a></h5>
                                    <p className="Hpara">Sep 19 2023 15:00 (IST)</p>
                                </div>
                                <div className="">
                                   <span class="circle"> </span>&nbsp;&nbsp;
                                   <img src="/static/media/tv-solid.0778e05556c1aa9e12f519d51d857ef1.svg" alt="" width="14"></img>
                                </div>
                              </div>
                              <div className="maintt">
                                    <div scope="col" className="temp">
                                      1
                                    </div>
                                    <div scope="col" className="temp">
                                      X
                                    </div>
                                    <div scope="col" className="temp">
                                      2
                                    </div>
                              </div>
                              <div className="maintt1">
                                     <div className="game-col">
								                     	<span class="main_blue">1.1</span>
								                     	<span class="main_pink">20</span>
                                       
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">10</span>
								                     	<span class="main_pink">0</span>
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">50</span>
								                     	<span class="main_pink">60</span>
								                     </div>
                              </div>

                            </div>

                            <div className="none">``
                              <div className="mainT">
                                <div className="">
                                    <h5 className="Hname"><a href="" class="main_color">Moro Canas v1 Barrere</a></h5>
                                    <p className="Hpara">Sep 19 2023 15:00 (IST)</p>
                                </div>
                                <div className="">
                                   <span class="circle"> </span>&nbsp;&nbsp;
                                   <img src="/static/media/tv-solid.0778e05556c1aa9e12f519d51d857ef1.svg" alt="" width="14"></img>
                                </div>
                              </div>
                              <div className="maintt">
                                    <div scope="col" className="temp">
                                      1
                                    </div>
                                    <div scope="col" className="temp">
                                      X
                                    </div>
                                    <div scope="col" className="temp">
                                      2
                                    </div>
                              </div>
                              <div className="maintt1">
                                     <div className="game-col">
								                     	<span class="main_blue">1.1</span>
								                     	<span class="main_pink">20</span>
                                       
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">10</span>
								                     	<span class="main_pink">0</span>
								                     </div>
                                     <div className="game-col">
								                     	<span class="main_blue">20</span>
								                     	<span class="main_pink">30</span>
								                     </div>
                              </div>

                            </div> */}

                            </div>
                            


                            {/* Mobile Mode Table End */}


                          </div>
                        </div>
                      </div>

                      <div
                        id="Home3"
                        class="container tab-pane active"
                        style={{ display: "none" }}
                      >
                        <div class="">
                          <div class="table-responsive-sm">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">id</th>
                                  <th scope="col">Name</th>
                                </tr>
                              </thead>
                              <tbody className="text-white">
                                {eventCatagory_Data?.map((item, index) => (
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.id}</td>
                                    <td>
                                      <a
                                        href=""
                                        style={{
                                          color: "#fff",
                                          textDecoration: "none",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            event_name === "Tennis"
                                              ? `/Tennis/${item.id}/${event_Type}`
                                              : event_name === "Soccer"
                                                ? `/Soccer/${item.id}/${event_Type}`
                                                : `/scoreboard/${item.id}/${event_Type}`
                                          )
                                        }
                                      >
                                        {item.name}
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div id="Home4" class="container tab-pane fade">
                        <br />
                        <h3>Home4</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                      <div id="Home5" class="container tab-pane fade">
                        <br />
                        <h3>Home5</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                      <div id="Home6" class="container tab-pane fade">
                        <br />
                        <h3>Home6</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                      <div id="Home7" class="container tab-pane fade">
                        <br />
                        <h3>Home7</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                      <div id="Home8" class="container tab-pane fade">
                        <br />
                        <h3>Home8</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                      <div id="Home9" class="container tab-pane fade">
                        <br />
                        <h3>Home9</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                      <div id="Home10" class="container tab-pane fade">
                        <br />
                        <h3>Home10</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                      <div id="Home11" class="container tab-pane fade">
                        <br />
                        <h3>Home11</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                      <div id="Home12" class="container tab-pane fade">
                        <br />
                        <h3>Home12</h3>
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam.
                        </p>
                      </div>
                    </div>




                  </div>
                </div>
              </>
            )}

            <div className="container">
              <div class="games_images">
                <div class="container">
                  <div class="row">
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/1.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/2.png" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/3.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/4.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/5.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/6.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/7.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/8.png" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/9.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/10.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/11.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets/images/games/12.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets1/images/card/teen1.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets1/images/card/roulette1.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets1/images/card/teen.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                          <img src="/assets1/images/card/teen20.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/poker20.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/teen9.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/teen8.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/poker.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/poker6.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/baccarat.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/dt6.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/dtl20.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/baccarat2.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/card32.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/abj.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/lucky7.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/lucky7eu.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/lucky7eu.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/war.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/aaa.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/worli.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/3cardj.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/ab20.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/card32eu.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/worli2.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/race20.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/lucky7eu2.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/superover.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/trap.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/patti2.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/cricketv3.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/cmatch20.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/queen.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/teen20b.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/teensin.jpg" />
                        </a>
                      </div>
                    </div>
                    <div class="col-3 col-md-2">
                      <div class="img_item">
                        <a>
                        <img src="/assets1/images/card/teenmuf.jpg" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-----=======body section end=========----> */}
      </main>
    </div>
  );
}

export default Dashboard;
