import React, { useState, useEffect } from "react";
import { API } from "../../API";
import swal from "sweetalert";
import axios from "axios";
import "./Login.css"
function Login() {
  const [UserName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setspinner] = useState(false);
  const [data, setData] = useState("");
  const IP_address = async () => {
    try {
      let res = await axios.get(`https://api.ipify.org?format=json`);
      res = res.data;
      console.log("IP_Addressres==>", res);
      setData(res.ip);
    } catch (e) {
      console.log("Error While Fatch Dashboard API", e);
    }
  };

  useEffect(() => {
    IP_address();
  }, []);

  const SessionClear = async () => {
    sessionStorage.clear();
  };

  const encryptdata = async (payload) => {
    try {
      let res;
      setspinner(true);
      let response = await API.get("getPublicKey");
      let publicKey = response.data.publicKey;
      if (publicKey) {
        const data = JSON.stringify(payload);

        const textBuffer = new TextEncoder().encode(data);

        // Convert base64 publicKey to Uint8Array
        const binaryString = window.atob(publicKey);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const cryptoKey = await window.crypto.subtle.importKey(
          "spki",
          bytes,
          {
            name: "RSA-OAEP",
            hash: "SHA-256",
          },
          true,
          ["encrypt"]
        );
        const encryptedData = await window.crypto.subtle.encrypt(
          {
            name: "RSA-OAEP",
          },
          cryptoKey,
          textBuffer
        );
        // Convert encryptedData to base64
        const base64EncryptedData = btoa(
          String.fromCharCode(...new Uint8Array(encryptedData))
        );
        setspinner(false);

        return base64EncryptedData;
      }
    } catch (e) {
      console.log("encrypt Api error:", e);
      setspinner(false);
    }
  };

  const handleName = (event) => {
    const newValue = event.target.value.replace(/[^A-Z , 0-9]/gi, "");
      setUserName(newValue)
  }

  const Login_APi = async (name,pswd) => {
    let username=name||UserName;
    let psword=pswd||password
    try {
      setspinner(true);
      let body = await encryptdata({
        username: username,
        password: psword,
        ipaddress: data,
      });
      let responce = await API.post("login_new", {
        encryptedData: body,
      });
      // let responce = await API.post("/login_new", {
        
      // });
      responce = responce.data.data;
      console.log("res", responce);
      let result = `User Name : ${responce.resultusername} & User Id : ${responce.resultid}`;
      if (responce.result == "Successfull") {
        // sessionStorage.setItem("token", token)
        sessionStorage.setItem("user", JSON.stringify(responce));
        sessionStorage.setItem('jwtToken',  JSON.stringify(responce.token));

        // Clear the token after 5 minutes
       
        swal({
          title: "Success..!",
          text: "Login has been successfull !!",
          icon: "success",
          button: "OK",
        }).then((okay) => {
          if (okay) {
            window.location.href = "./Dashboard/InPlay/0";
          }
        });
      } else {
        swal({
          title: "Registration Error..!!",
          text: responce.result,
          icon: "error",
          button: "OK",
        });
      }
    } catch (e) {
      console.log("Something Error", e);
    }
  };
  useEffect(() => {
    SessionClear();
  }, []);

  return (
   
    <div className="main_loginPage">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 log_maina">
            <div className="text-center login_logo">
              <img
                className="w-50"
                src="/assets/images/bet21-logo.png"
                alt=""
              />
            </div>
            <div className="login_form new_login_form registerForm mt-4 ">
              <h4 className="login_heading">
                login{" "}
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 384 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M91.826 467.2V317.966c-8.248 5.841-16.558 10.57-24.918 14.153C35.098 345.752-.014 322.222 0 288c.008-18.616 10.897-32.203 29.092-40 28.286-12.122 64.329-78.648 77.323-107.534 7.956-17.857 25.479-28.453 43.845-28.464l.001-.002h171.526c11.812 0 21.897 8.596 23.703 20.269 7.25 46.837 38.483 61.76 38.315 123.731-.007 2.724.195 13.254.195 16 0 50.654-22.122 81.574-71.263 72.6-9.297 18.597-39.486 30.738-62.315 16.45-21.177 24.645-53.896 22.639-70.944 6.299V467.2c0 24.15-20.201 44.8-43.826 44.8-23.283 0-43.826-21.35-43.826-44.8zM112 72V24c0-13.255 10.745-24 24-24h192c13.255 0 24 10.745 24 24v48c0 13.255-10.745 24-24 24H136c-13.255 0-24-10.745-24-24zm212-24c0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20 20-8.954 20-20z"></path>
                </svg>
              </h4>
              <form>
                <div className="form-group">
                  <div className="inpuT_main_div">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="User Name"
                      name="username"
                      value={UserName}
                      onChange={(e) => handleName(e)}
                      // onChange={(e) => setUserName(e.target.value)}
                    />
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      className="input_icons"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
                    </svg>
                  </div>
                </div>
                <div className="form-group">
                  <div className="inpuT_main_div">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="pswd"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        className="input_icons"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797 0.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-group form-check set_dflex ps-0">
                  <div className="login_btn">
                    <button type="button" className="btn button btn-block" onClick={() => Login_APi()}>
                      Sign In{" "}
                    </button>
                  </div>
                  {/* <div className="login_btn">
                    <button type="button" className="btn button btn-block" 
                    onClick={() =>(Login_APi('Demo','123456'))}
                    >
                      Demo Sign In{" "}
                    </button>
                  </div> */}
                  {/* <label className="link_text">
                    <a href="/Forgot">Forgot Password </a>
                  </label> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
