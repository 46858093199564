import React, { useEffect, useState } from "react";
import { API, API_Match } from "../../API";
import "./Header.css";
import { useNavigate, useParams } from "react-router-dom";
import tannis from "../Dashboard/2.png";
import criball from "../Dashboard/4.png";
import horse from "../Dashboard/5.png";
import football from "../Dashboard/1.png";
import landmark from "../../Assets/landmark-icon.jpg";

function Navbar({ updateBal,data_Dashboard }) {
  const user = sessionStorage.getItem("user");
  let ress = JSON.parse(user);
  let UName = ress.resultusername;
  let userId = ress.resultid;
  const [data, setData] = useState("0.00");
  const [events_Data, setevents_Data] = useState([]);
  const [eventCatagorydata, seteventCatagory] = useState([]);
  // const [eventCatagory_Data, seteventCatagory_Data] = useState([]);
  // const [event_name, setevent_name] = useState("");
  // const [event_Type, setevent_Type] = useState(0);
  const [exp,setExp]=useState(0)
  let { name, type } = useParams();
  const navigate = useNavigate();

  const Live_Rate = async () => {
    try {
      let res = await API.post("/getbetawallet", {
        username: UName,
      });
      console.log("r1",res)
      setExp(res.data.data[0][0].exposure)
      res = res.data.data[0][0].totalnetbal;
      setData(res);
    } catch (e) {
      console.log("Error While Fatch Dashboard API", e);
    }
  };

  const getAllEvents = async () => {
    try {
      let res = await API.get("GetAllMatches");
      // console.log("getAllEvents=>", res.data);
      setevents_Data(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const eventCatagory = async () => {
  //   try {
  //     let res = await API_Match.get(
  //       `GetAllCompetitionsData?EventTypeID=${type}`
  //     );
  //     //   console.log("Res", res.data);
  //     seteventCatagory(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   eventCatagory();
  // }, [type]);

  useEffect(() => {
    Live_Rate();
    getAllEvents();
  }, [updateBal]);

  function stop() {
    document.getElementById("marquee1").stop();
  }

  function start() {
    document.getElementById("marquee1").start();
  }

  const [dd, setdd] = useState(false);

  function handleClick() {
    setdd(!dd);
  }

  function Logout() {
    sessionStorage.clear();
    navigate("/");
  }

  // const Current_Match = async (EventTypeID) => {
  //   try {
  //     let res = await API.get(
  //       `GetAllCurrentMatches?eventTypeID=${EventTypeID}`
  //     );
  //     // res = res.data.data;
  //     seteventCatagory_Data(res.data.data);
  //     console.log("Current_Match", res.data.data);
  //   } catch (e) {
  //     console.log("Error While Fatch Current_Match API", e);
  //   }
  // };

 
  return (
    <div>
      <header className="top_header bet_icon_header">
        <div className={data_Dashboard=="none" ? "container-fluid head_box":"container-fluid"}>
          <div className="row">
            <div className="">
              <div className="Header_main_balance">
                <div className="Left_items ">
                  <a href="/Dashboard/InPlay/0">
                    <img
                      src="/assets/images/bet21-logo.png"
                      alt="logo"
                      className="nav_logo"
                    />
                  </a>
                </div>
                <div>
                  <div className="header_top_righ12  ">
                    <div className="bal">
                      <p className="balance_nav_res  p-0 m-0 ">
                        <img src={landmark} alt="" /> :{data || 0.0}
                      </p>
                      <p className=" Exposure_res">
                        <span className="me-2">Exp: {exp||0.0}</span>{" "}
                        <div class="dropdown ">
                          <p
                            class="dropdown-toggle p-0 m-0"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {/* {UName == "Demo"?
                            <>{UName}</>:<>{userId}</>
                          } */}
                           {UName}
                          </p>
                          <ul
                            class={`dropdown-menu hit ${dd ? "show" : ""}`}
                            aria-labelledby="dropdownMenuLink"
                          >
                            <li>
                              <a
                                class="mbf dropdown-item"
                                href="/Dashboard/InPlay/0"
                              >
                                <span className="fafa"> </span> Home
                              </a>
                            </li>

                            <li>
                              <a class="dropdown-item" href="/Statement">
                                <span className="fafa"> </span> Account
                                Statement
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/ProfitLoss">
                                <span className="fafa"> </span> Profit Loss
                                Report
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/Bet_History_Drop">
                                <span className="fafa"> </span> Bet History
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/Unsellected">
                                <span className="fafa"> </span> Unsetteled Bet
                              </a>
                            </li>

                            <li>
                              <a class="dropdown-item" href="/Change_Password">
                                <span className="fafa"> </span> Change Password
                              </a>
                            </li>
                            <hr />
                            <li>
                              <a class="dropdown-item" href="" onClick={Logout}>
                                <span className="fafa">
                                  {" "}
                                  <i className="fa fa-sign-out"></i>
                                </span>{" "}
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </p>
                      {/* <h3>Exposure: 0</h3> */}
                    </div>
                  </div>
                  <div className="header_top_righ  ">
                    <div className="bal">
                      <h3>
                        <span>Balance:</span>
                        {data}
                      </h3>
                      <h3>Exposure: {exp}</h3>
                    </div>
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* {UName == "Demo"?
                            <>{UName}</>:<>{userId}</>
                          } */}
                          {UName}
                           
                      </button>
                      <ul
                        class={`dropdown-menu hit ${dd ? "show" : ""}`}
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a
                            class="mbf dropdown-item"
                            href="/Dashboard/InPlay/0"
                          >
                            <span className="fafa"> </span> Home
                          </a>
                        </li>

                        <li>
                          <a class="dropdown-item" href="/Statement">
                            <span className="fafa"> </span> Account Statement
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/ProfitLoss">
                            <span className="fafa"> </span> Profit Loss Report
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/Bet_History_Drop">
                            <span className="fafa"> </span> Bet History
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/Unsellected">
                            <span className="fafa"> </span> Unsetteled Bet
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/Button_value">
                            <span className="fafa"> </span> Set Button Values
                          </a>
                        </li>

                        <li>
                          <a class="dropdown-item" href="/Change_Password">
                            <span className="fafa"> </span> Change Password
                          </a>
                        </li>
                        <hr />
                        <li>
                          <a class="dropdown-item" href="" onClick={Logout}>
                            <span className="fafa">
                              {" "}
                              <i className="fa fa-sign-out"></i>
                            </span>{" "}
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="account-setting">
                      <h3 className="head_name">{userId}</h3>
                      <div class="dropdown drc1">
                        <a
                          class="btn btn-secondary dropdown-toggle menus"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={handleClick}
                        ></a>

                        <ul
                          class={`dropdown-menu hit ${dd ? "show" : ""}`}
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              class="mbf dropdown-item"
                              href="/Dashboard/InPlay/0"
                            >
                              <span className="fafa"> </span> Home
                            </a>
                          </li>

                          <li>
                            <a class="dropdown-item" href="/Statement">
                              <span className="fafa"> </span> Account Statement
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="/ProfitLoss">
                              <span className="fafa"> </span> Profit Loss Report
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="/Bet_History_Drop">
                              <span className="fafa"> </span> Bet History
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="/Unsellected">
                              <span className="fafa"> </span> Unsetteled Bet
                            </a>
                          </li>

                          <li>
                            <a class="dropdown-item" href="/Change_Password">
                              <span className="fafa"> </span> Change Password
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a class="dropdown-item" href="" onClick={Logout}>
                              <span className="fafa">
                                {" "}
                                <i className="fa fa-sign-out"></i>
                              </span>{" "}
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
              </div>
            </div>

            <div className=" max_nav_search">
              <div className="top_marque">
                <marquee
                  id="marquee1"
                  direction="left"
                  scrollamount="4"
                  onMouseOver={stop}
                  onMouseOut={start}
                >
                  Welcome to demo. For Buy Fund Call Our India Coordinator.
                  12345
                </marquee>
              </div>
            </div>
          </div>
          <div className="row nav_tab_bg">
            <div className="col-md-12">
              <div className="header_menu">
                <nav className="navbar navbar-expand-md navbar-dark toggle_btn">
                  <div
                    className="offcanvas offcanvas-start"
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                  >
                    <div className="offcanvas-header">
                      <button
                        type="button"
                        className="btn-close mob_reset text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="mob">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <a className="nav-link" href="/Dashboard/InPlay/0">
                            <span className="fafa">
                              {" "}
                              <i className="fa fa-home"></i>
                            </span>{" "}
                            Home
                          </a>
                        </li>

                        {events_Data.slice(0, 12).map((items, index) => {
                          // console.log("EventNave",items);
                          return (
                            <>
                              <li className="nav-item" key={index}>
                                <a
                                  className="nav-link cool-link"
                                  onClick={() =>
                                    navigate(
                                      `/Dashboard/${items.name}/${items.eventType}`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {items.name==='Soccer'?'Football':items.name}
                                </a>
                              </li>
                            </>
                          );
                        })}
{/* 
                        <li className="nav-item">
                          <a href="0" className="nav-link">
                            Horse Racing
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="0" className="nav-link">
                            Greyhound Racing
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="0" className="nav-link">
                            Kabaddi
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="0" className="nav-link">
                            Politics
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="0" className="nav-link">
                            Casino
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="0" className="nav-link">
                            sports Book
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="0" className="nav-link">
                            INT Casino
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="0" className="nav-link">
                            Binary
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a className="nav-link" href="" onClick={Logout}>
                            <span className="fafa">
                              {" "}
                              <i className="fa fa-sign-out"></i>
                            </span>{" "}
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* {name && type && (
                    <button
                      className="btn off btn-primary mod-slide"
                      type="button"
                      style={{ height: "32px", marginLeft: "-32%" }}
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample2"
                      aria-controls="offcanvasExample2"
                    >
                      <i className="fa fa-bars"></i>
                    </button>
                  )} */}

                  <div
                    class="offcanvas offcanvas-start offcamp1"
                    tabindex="-1"
                    id="offcanvasExample2"
                    aria-labelledby="offcanvasExampleLabel"
                  >
                    <div className="container can_er1">
                      <button
                        type="button"
                        class="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>

                      <ul className="list-unstyled components">
                        <button
                          className="badge badge-info "
                          style={{ fontSize: "16px" }}
                        >
                          {name}
                        </button>
                        <hr style={{ color: "#fff" }} />
                        <li className="active sideMobile">
                          {eventCatagorydata?.map((item, index) => (
                            <ul
                              className="collapse list-unstyled show"
                              id="homeSubmenu"
                              key={index}
                            >
                              <li className="lisize">
                                <a
                                  href=""
                                  style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      name === "Tennis"
                                        ? `/Tennis/${item.competition.id}/${type}`
                                        : name === "Soccer"
                                        ? `/Soccer/${item.competition.id}/${type}`
                                        : `/scoreboard/${item.competition.id}/${type}`
                                    )
                                  }
                                >
                                  {item?.competition?.name}
                                </a>
                              </li>
                            </ul>
                          ))}
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
